<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'StaffList',
              }"
            >
              控制中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員卡畫面</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">會員卡畫面</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="lg"
              label="表頭"
              label-class="font-weight-bold py-1"
              class="pt-4 border-bottom"
            >
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【表頭標題】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="headerSection.enable_page_title"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【表頭標題】文字"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-input
                  v-model="pageTitle"
                  placeholder="【表頭標題】文字"
                  :disabled="moduleNotFound"
                ></b-form-input>
                <b-form-text id="input-live-help"
                  >若未設定標題，將顯示全域標題</b-form-text
                >
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【表頭選單】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="headerSection.enable_menu_items"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                v-if="headerSection.enable_menu_items"
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="左選單"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-select
                  v-model="headerSection.menu_items[0]"
                  class="mb-2"
                  :disabled="moduleNotFound"
                  :options="
                    menuItemOption.map((x) => {
                      return {
                        text: x.name,
                        value: {
                          float: 'left',
                          title: x.name,
                          type: x.type,
                        },
                      };
                    })
                  "
                ></b-form-select>
              </b-form-group>

              <b-form-group
                v-if="headerSection.enable_menu_items"
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="右選單"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-select
                  v-model="headerSection.menu_items[1]"
                  :disabled="moduleNotFound"
                  :options="
                    menuItemOption.map((x) => {
                      return {
                        text: x.name,
                        value: {
                          float: 'right',
                          title: x.name,
                          type: x.type,
                        },
                      };
                    })
                  "
                ></b-form-select>
              </b-form-group>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="lg"
              label="卡片"
              label-class="font-weight-bold py-1"
              class="pt-4 border-bottom"
            >
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員卡片】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="memberCardSection.enable"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員卡片】版型"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-select
                  v-model="memberCardSection.card_layout"
                  class="mb-2"
                  :options="cardLayoutOption"
                  :disabled="moduleNotFound"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【LOGO】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <button
                  class="
                    btn btn-link
                    px-2
                    text-dark
                    align-self-start
                    upload-button
                  "
                  :disabled="moduleNotFound"
                >
                  <b-form-file @change="upload($event)" />
                  <a
                    v-if="!!memberCardSection.logo"
                    @click="memberCardSection.logo = null"
                    class="btn-sm btn-outline-danger"
                    :disabled="moduleNotFound"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                  <a :href="memberCardSection.logo" target="_blank"
                    ><small class="text-truncate d-inline-block" style="max-width: 250px;"> 目前路徑：{{ memberCardSection.logo || "未上傳" }} </small></a
                  >
                </button>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員名稱】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="memberCardSection.display_name"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員點數】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="memberCardSection.display_points"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員點數】名稱"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-input
                  v-model="memberCardSection.points_name"
                  placeholder="【會員點數】名稱"
                  :disabled="moduleNotFound"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【會員條碼】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="memberCardSection.display_barcode"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【個人頭像】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="memberCardSection.display_avator"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="lg"
              label="選單圖示"
              label-class="font-weight-bold py-1"
              class="pt-4 border-bottom"
            >
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="【選單圖示】顯示"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-checkbox
                  class="py-1"
                  v-model="menuIconSection.enable"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="moduleNotFound"
                />
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="sm"
                label="每列圖示數量"
                label-class="font-weight-bold text-lg-right"
              >
                <b-form-select
                  v-model="menuIconSection.column_number"
                  class="mb-2"
                  :options="[1, 2, 3, 4]"
                  :disabled="moduleNotFound"
                ></b-form-select>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <CardReview
              :pageTitle="pageTitle"
              :headerSection="headerSection"
              :memberCardSection="memberCardSection"
              :sections="[menuIconSection]"
            ></CardReview>
          </b-col>
        </b-row>
      </b-overlay>

      <div class="d-flex justify-content-center mt-3">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            class="mr-2"
            variant="success"
            @click="handleSubmit(false)"
            :disabled="isLoading || moduleNotFound"
          >
            儲存
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
            :disabled="isLoading || moduleNotFound"
          >
            儲存後關閉
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
//import _ from "lodash";

import CardReview from "@/components/Dashboard/LiffCard/CardReview";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";
import fileMixin from "@/mixins/uploadFile";
//import _ from 'lodash'

export default {
  mixins: [moduleMap, fileMixin],
  components: {
    CardReview,
  },

  //components: {editor, 'color-picker': ColorPicker},
  validationConfig: {
    $lazy: true,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      pageTitle: null,
      pageImage: null,
      isLoading: false,
      isSubmmiting: false,
      headerSection: null,
      memberCardSection: null,
      menuIconSection: null,
      cardLayoutOption: ["10", "20", "30", "40", "41", "50", "51", "60"],
      moduleNotFound: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.$route.params.orgId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    menuItemOption() {
      return [
        ...this.moduleMap,
        {
          name: "返回",
          type: "go_back",
        },
      ];
    },
  },
  validations() {
    let rules = {};

    return rules;
  },
  async mounted() {
    await this.$store.dispatch("liffGeneral/init", this.$route.params.org_code);
    await this.$store.dispatch("liffModule/fetchModule", "member_center");

    if (!this.$store.getters["liffModule/getModule"]("member_center")) {
      this.$swal.fire({
        icon: "error",
        title: "錯誤",
        text: `模組 member_center 未啟用`,
      });
      this.moduleNotFound = true;
    } else {
      this.pageTitle = this.getModuleConfig("page_title");
      this.pageImage = this.getModuleConfig("page_image");
      this.headerSection = this.getModuleConfig("header_section");
      this.memberCardSection = this.getModuleConfig("member_card_section");
      this.menuIconSection = this.getModuleConfig("menu_icon_section");
      this.menuIconSection.key = "menu_icon_section";
    }

    // 排序 section
    // let sectionKeys = [
    //   "menu_icon_section",
    //   "menu_list_section",
    //   "member_card_section",
    // ];

    // let sections = [];
    // for (let i = 0; i < sectionKeys.length; i++) {
    //   let config = this.getModuleConfig(sectionKeys[i]);

    //   config.key = sectionKeys[i];

    //   if (config.enable === true) {
    //     sections.push(config);
    //   }
    // }
    // this.sections = _.sortBy(sections, "order");

    // // 排序 menu_icon_section
    // if (this.sections.some((x) => x.key == "menu_icon_section")) {
    //   let section = this.sections.find((x) => x.key == "menu_icon_section");
    //   section.menu_items = _.sortBy(section.menu_items, "order");
    // }

    // // 排序 menu_list_section
    // if (this.sections.some((x) => x.key == "menu_list_section")) {
    //   let section = this.sections.find((x) => x.key == "menu_list_section");
    //   section.menu_items = _.sortBy(section.menu_items, "order");
    // }

    // // 排序 detail fields
    // let memberDetailSection = this.getModuleConfig("member_detail_section");

    // if (memberDetailSection.enable === true) {
    //   let fields = memberDetailSection.fields.map((field) => {
    //     let isExtra =
    //       field.matched_key && field.matched_key.startsWith("extra.");

    //     return {
    //       ...field,
    //       is_extra: isExtra,
    //       field_name: isExtra
    //         ? field.matched_key.substring(6)
    //         : field.matched_key,
    //     };
    //   });

    //   this.detail_fields = _.sortBy(fields, "order");
    // }
  },
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_center", key);
    },
    async upload(e) {
      this.isLoading = true;
      let url = await this.uploadFile(e.target.files[0]);
      this.memberCardSection.logo = url;
      this.isLoading = false;
      return url;
    },
    handleCancel: function () {},
    handleSubmit: function () {},
  },
};
</script>
